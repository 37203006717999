import type { IconProps } from '../../../../../src/features/common/types/common';

export const ProjectStatIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect width="24" height="24" rx="3.09677" fill="#6FCF97" />
      <path
        d="M11.9607 11.6436C9.74982 11.6436 7.95312 13.3929 7.95312 15.5528C7.95312 15.555 7.95312 15.5583 7.95312 15.5605C7.95312 15.9887 8.32964 16.3333 8.78735 16.3333H15.1352C15.5917 16.3333 15.9671 15.9898 15.9694 15.5627C15.9694 15.5594 15.9694 15.5561 15.9694 15.5528C15.9682 13.394 14.1739 11.6436 11.9607 11.6436Z"
        fill="white"
      />
      <path
        d="M13.9473 8.07353C14.5792 9.09954 14.2038 10.4107 13.1072 11.0019C12.0106 11.593 10.6093 11.2419 9.97741 10.2158C9.34557 9.18982 9.72091 7.87867 10.8175 7.2875C11.9129 6.69523 13.3143 7.04751 13.9473 8.07353Z"
        fill="white"
      />
      <path
        d="M14.8065 10.1185C15.1853 10.3761 15.6572 10.4851 16.1208 10.4224C16.5843 10.3596 17.0032 10.1295 17.288 9.78167C17.5727 9.43379 17.7021 8.99564 17.6468 8.5608C17.5915 8.12595 17.3574 7.72743 16.9926 7.45221C16.6279 7.17699 16.1631 7.04489 15.6972 7.08562C15.2312 7.12635 14.7994 7.33552 14.4958 7.66908C15.0536 8.38025 15.1724 9.3127 14.8065 10.1262V10.1185Z"
        fill="white"
      />
      <path
        d="M15.8584 10.7419C15.0854 10.743 14.3406 11.0183 13.7747 11.5115C13.8676 11.5434 13.9594 11.5764 14.0488 11.6138C14.5748 11.8329 15.0501 12.1467 15.4478 12.5375C15.9914 13.0648 16.3797 13.7154 16.575 14.4255H18.3364C18.6976 14.4255 18.9965 14.1547 19 13.8167C19 13.8145 19 13.8123 19 13.8101C19 12.118 17.5928 10.7419 15.8584 10.7419Z"
        fill="white"
      />
      <path
        d="M9.87355 11.616C9.98298 11.5709 10.0912 11.5291 10.2042 11.4938C9.64058 11.0117 8.90519 10.743 8.14157 10.7419C6.40724 10.7419 5 12.118 5 13.8101C5 13.8123 5 13.8145 5 13.8167C5.00118 14.1547 5.29886 14.4255 5.65891 14.4255H7.34618C7.54149 13.7154 7.92978 13.0648 8.47338 12.5364C8.87225 12.1478 9.3476 11.8351 9.87355 11.616Z"
        fill="white"
      />
      <path
        d="M8.14151 10.438C8.49449 10.4402 8.84042 10.3433 9.13457 10.1628C8.94043 9.75102 8.86866 9.29746 8.92749 8.8505C8.98514 8.40355 9.17105 7.97971 9.46402 7.62302C9.05339 7.20579 8.44507 7.01094 7.84735 7.10341C7.24963 7.19698 6.74251 7.56578 6.49777 8.08429C6.25421 8.60281 6.30598 9.20168 6.63661 9.67726C6.96724 10.1528 7.53084 10.4402 8.1368 10.4413L8.14151 10.438Z"
        fill="white"
      />
    </svg>
  );
};

export const CountryStatIcon = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame 1171275482">
        <path
          id="Vector"
          d="M6.07234 0.193604C5.33605 0.193604 4.7649 0.700204 4.06645 1.21032C3.39553 1.70285 2.60419 2.23408 1.4619 2.50849L1.38276 2.31851C1.45158 2.22704 1.4963 2.11447 1.4963 1.98782C1.4963 1.68174 1.25546 1.43548 0.959567 1.43548C0.663672 1.43548 0.419388 1.68174 0.419388 1.98782C0.419388 2.26926 0.625826 2.49793 0.890754 2.5296L5.01607 12.5807L5.51152 12.3696L3.65358 7.84539C4.59975 7.54987 5.28444 7.08901 5.87278 6.72313C6.50586 6.33262 7.00475 6.06173 7.66191 6.12506C7.78233 6.13561 7.8133 6.16727 7.86491 6.26578C7.98877 6.57889 7.96469 6.90607 7.99221 7.22269C8.02318 7.52173 8.17801 7.70115 8.48422 7.61671C9.79166 6.94477 11.0853 6.68091 12.5338 6.68091C12.6371 6.68091 12.7368 6.61759 12.7816 6.5226C12.8263 6.42761 12.8091 6.308 12.7437 6.22708C11.6806 4.89022 10.0772 3.00805 10.7585 1.40734C10.8204 1.26661 10.7413 1.08016 10.6002 1.0309C9.73317 0.735385 8.79388 1.00276 7.88211 1.38974C7.3557 0.823337 6.76047 0.207676 6.07234 0.193604ZM1.9023 3.58502C2.63171 4.3414 2.93449 5.2174 2.96201 6.16375L1.9023 3.58502Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export const InfoIcon = ({ width }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      viewBox="0 0 10 10"
      fill="none"
    >
      <g clipPath="url(#clip0_1929_5708)">
        <path
          d="M5 0C2.23889 0 0 2.23889 0 5C0 7.76111 2.23889 10 5 10C7.76111 10 10 7.76111 10 5C10 2.23889 7.76111 0 5 0ZM5.88556 7.09889L5.82444 7.30333C5.81444 7.33889 5.79333 7.37 5.76444 7.39333C5.42 7.65778 4.97889 7.76111 4.55333 7.67778L4.55667 7.67333C4.54556 7.67111 4.53444 7.67 4.52333 7.66667C4.15667 7.57556 3.93333 7.20556 4.02444 6.83889L4.5 4.92444C4.61333 4.46556 4.11 4.57444 3.90222 4.63778C3.87667 4.64556 3.85 4.63111 3.84222 4.60556C3.84 4.59667 3.84 4.58667 3.84222 4.57778L3.90333 4.37333C3.91333 4.33778 3.93444 4.30667 3.96333 4.28333C4.30778 4.01889 4.74889 3.91556 5.17444 3.99889C5.17444 3.99889 5.20111 4.00333 5.21444 4.00667C5.58111 4.09778 5.80444 4.46778 5.71333 4.83444L5.21333 6.84778C5.19111 7.19444 5.63333 7.09778 5.82667 7.03889C5.85222 7.03111 5.87889 7.04556 5.88667 7.07111C5.88889 7.08 5.88889 7.09 5.88667 7.09889H5.88556ZM5.44556 3.72444C5.05111 3.72444 4.73111 3.40444 4.73111 3.01C4.73111 2.61556 5.05111 2.29556 5.44556 2.29556C5.84 2.29556 6.16 2.61556 6.16 3.01C6.16 3.40444 5.84 3.72444 5.44556 3.72444Z"
          fill="#828282"
        />
      </g>
      <defs>
        <clipPath id="clip0_1929_5708">
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
